import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FilterInput from '../../components/InternalValueTextField';
import { useState, useEffect,Fragment } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {  ProcessStatus, useProcessState, ProcessState } from "@alethea-medical/alethea-components";
import { NoReadWriteTextField } from '@alethea-medical/react-components';
import db from '../../firebase/db'
import app from "../../firebase";
import LeavePagePrompt from '../../components/LeavePagePrompt';

interface EditDxCodesProps {
    classes? : any;
}

const EditDxCodes = ({classes} : EditDxCodesProps) => {

    const [ selectedSpecialty, setSelectedSpecialty ] = useState<string | null>(null);
    const [ selectedSubsite, setSelectedSubsite ] = useState<string | null>(null);
    const [ newDxCode, setNewDxCode] = useState<string | null>(null);

    const [ existingSpecialties, setExistingSpecialties ] = useState<{[specialty: string] : string[]}>({});
    const [ dxCodes, setDxCodes ] = useState<{[subsite: string]: string}>({});

    const [ formIsModified, setFormIsModified ] = useState<boolean>(false);

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({});

    const mdQuery = app.functions().httpsCallable("portalV2-query");


    const submit = () => {

        // validate specialty
        if(!selectedSpecialty) {
            errorHandler({userMessage: "Specialty is required"});
            return;
        }

        // validate all dx codes
        for(const subsite in dxCodes) {
            if(!validateDxCode(subsite, dxCodes[subsite])) return;
        }

        // update dx codes
        setProcessState(ProcessState.running);
        db.collection("dx_codes").doc(selectedSpecialty).set(dxCodes).then(() => {
            setProcessState(ProcessState.success);
            setFormIsModified(false);
        }).catch((error) => {
            errorHandler({userMessage: "Error updating Dx Codes"});
        })
    }

    const reset = () => {
        // reset form
        setSelectedSubsite(null);
        setNewDxCode(null);
        setDxCodes({});
        setProcessState(ProcessState.idle);
        setFormIsModified(false);
        if(selectedSpecialty) {
            getDxCodes(selectedSpecialty);
        }
    }


    const removeDxCode = (specialty: string, subsite: string) => {
        setProcessState(ProcessState.idle);
        setFormIsModified(true);

        // remove dx code
        const newDxCodes = {...dxCodes};
        delete newDxCodes[subsite];
        setDxCodes(newDxCodes)
    }

    const addDxCode = (subsite: string, dxCode: string | null) => {

        if(!dxCode) {
            errorHandler({userMessage: "Dx Code is required"});
            return;
        }

        setProcessState(ProcessState.idle);
        setFormIsModified(true);

        const newDxCodes = {...dxCodes};
        newDxCodes[subsite] = dxCode;
        setDxCodes(newDxCodes);

        setNewDxCode(null);
        setSelectedSubsite(null);
    }

    const validateDxCode = (subsite: string, dxCode: string | null) => {

        let errorMessage = null;

        if(!dxCode){
            errorMessage = "Dx Code is required"
        }else{
            if(dxCode.length > 6) errorMessage = "Dx Code must be 6 characters or less";
            if(dxCode.trim() === "") errorMessage = "Dx Code cannot be empty";    
        }

        if(errorMessage) {
            errorHandler({userMessage: `Error in ${subsite}: ${errorMessage}`});
            return false;
        }

        return true;
    }

    const isDisabled = () => {
        return processState === ProcessState.running
    }

    const getSelectableSubsites = (specialty: string) => {
        if(!existingSpecialties[specialty]) return [];

        if(!dxCodes) return existingSpecialties[specialty];

        return existingSpecialties[specialty].filter((subsite: string) => {
            return !dxCodes[subsite];
        })
    }

    const getDxCodes = (specialty: string) => {
        setProcessState(ProcessState.running);
        db.collection("dx_codes").doc(specialty).get().then((doc) => {
            if(doc.exists) {
                setDxCodes(doc.data() as {[subsite: string]: string})
            } else {
                setDxCodes({})
            }
            setProcessState(ProcessState.idle);
        }).catch((error) => {
            errorHandler({userMessage: "Error fetching Dx Codes"});
        })
    }

    const getExistingSpecialties = () => {
        setProcessState(ProcessState.running);
        mdQuery({path: "specialties_v2"}).then((result) => {
            const specialties: {[specialty: string] : string[]} = {};
            result.data.forEach((doc: any) => {
                specialties[doc.id] = [];
                Object.keys(doc.data).forEach((subsite: string) => {
                    specialties[doc.id].push(subsite);
                })
            })
            setExistingSpecialties(specialties);
            setProcessState(ProcessState.idle);
        }).catch((error) => {
            errorHandler({userMessage: "Error fetching specialties"});

        })
    }

    useEffect(() => {
        getExistingSpecialties();
    }, [])

    useEffect(() => {
        reset()
    }, [selectedSpecialty])

    return (
            <form className={classes.form}>
                <fieldset disabled={isDisabled()}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} className={classes.formItem}>
                            <Autocomplete
                                options={Object.keys(existingSpecialties)}
                                getOptionLabel={(option: string) => option}
                                value={selectedSpecialty}
                                onChange={(event, newValue, reason) => {
                                    if(reason === "clear") {
                                        setSelectedSpecialty(null);
                                        reset();
                                        return;
                                    }
                                    setSelectedSpecialty(newValue as string);
                                }}
                                renderInput={(params : any) => <TextField {...params} label="Specialty" variant="outlined" />}
                            />
                        </Grid>
                        {selectedSpecialty &&
                            <>                                           
                                <Grid item xs={4} className={classes.formItem}>
                                    <NoReadWriteTextField
                                        label={"Subsite"}
                                        value={"default"}
                                    />
                                </Grid>
                                <Grid item xs={4} className={classes.formItem}>
                                    <FilterInput 
                                        fullWidth
                                        label="Dx Code"
                                        value={dxCodes["default"] ? dxCodes["default"] : ""}
                                        onValueChange={(newValue: string) => {addDxCode("default", newValue)}}
                                    />
                                </Grid>
                                <Grid item xs={4} className={classes.formItem}>
                                </Grid>
                                {dxCodes && Object.keys(dxCodes).sort().map((subsite: string) => {
                                    if(subsite !== "default"){
                                        return (
                                            <Fragment key={subsite}>
                                                <Grid item xs={4} className={classes.formItem}>
                                                <NoReadWriteTextField
                                                    label={"Subsite"}
                                                    value={subsite}
                                                />
                                                </Grid>
                                                <Grid item xs={4} className={classes.formItem}>
                                                    <FilterInput 
                                                        fullWidth
                                                        label="Dx Code"
                                                        value={dxCodes[subsite]}
                                                        onValueChange={(newValue: string) => {addDxCode(subsite, newValue)}}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.formItem}>
                                                    <Button variant="outlined" color="primary" onClick={() => {removeDxCode(selectedSpecialty, subsite)}}>
                                                        Remove Dx Code
                                                    </Button>
                                                </Grid>
                                            </Fragment>
                                        )}
                                    }

                                )}
                                {
                                    Object.keys(getSelectableSubsites(selectedSpecialty)).length > 0 &&
                                    <>
                                        <Grid item xs={4} className={classes.formItem}>
                                            <Autocomplete
                                                disabled = {selectedSpecialty === null}
                                                options={getSelectableSubsites(selectedSpecialty)}
                                                getOptionLabel={(option: string) => option}
                                                value={selectedSubsite}
                                                onChange={(event, newValue) => {setSelectedSubsite(newValue as string)}}
                                                renderInput={(params : any) => <TextField {...params} label="Subsite" variant="outlined" />}
                                            />
                                        </Grid>
                                        {selectedSubsite &&
                                            <>
                                                <Grid item xs={4} className={classes.formItem}>
                                                    <FilterInput 
                                                        fullWidth
                                                        label="Dx Code"
                                                        value={dxCodes ? dxCodes[selectedSubsite] : ""}
                                                        onValueChange={(newValue: string) => {setNewDxCode(newValue)}}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} className={classes.formItem}>
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary" 
                                                        onClick={() => {addDxCode(selectedSubsite, newDxCode)}}
                                                    >
                                                        Add Dx Code
                                                    </Button>
                                                </Grid>
                                            </>
                                        }
                                    </>
                                }
                                <Grid item xs={12} className={classes.formItem}>
                                    <Button variant="contained" color="primary" onClick={() => submit()} disabled={!formIsModified || isDisabled()}>
                                        Save Changes 
                                    </Button>
                                    <Button className={classes.dangerButton} variant="contained" style={{marginLeft: "10px"}} onClick={() => reset()} disabled={!formIsModified || isDisabled()}>
                                        Cancel 
                                    </Button>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <ProcessStatus state={processState} errorMessage={processErrorMessage} successMessage={"Dx Codes updated successfully"}/>
                        </Grid>
                    </Grid>
                </fieldset>
                <LeavePagePrompt isDirty={formIsModified}/>
            </form>
    )
}




export default EditDxCodes;