import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { Specialist } from '@alethea-medical/aletheamd-types';
import Divider from '@material-ui/core/Divider';
import FilterInput from '../../components/InternalValueTextField';

interface EditSpecialtyProps {
    specialties: Specialist.SpecialtyDict,
    setSpecialties: (specialties: Specialist.SpecialtyDict) => void,
    isDisabled?: boolean
}

const EditSpecialty = ({ specialties, setSpecialties, isDisabled }: EditSpecialtyProps) => {
    
    const addSpecialty = () => {
        setSpecialties({
            ...specialties,
            [""]: {}
        })
    }

    const removeSpecialty = (specialty: string) => {
        // If not empty, then confirm remove
        if(specialty !== "" || Object.keys(specialties[specialty]).length > 0) {
            if(!window.confirm(`Remove specialty: ${specialty}?`))
                return
        }
        const newSpecialties = {...specialties}
        delete newSpecialties[specialty]
        setSpecialties(newSpecialties)
    }


    const addSubsite = (specialty: string) => {
        setSpecialties({
            ...specialties,
            [specialty]: {
                ...specialties[specialty],
                [""]: { priority: 0 }
            }
        })
    }
    const removeSubsite = (specialty: string, subsite: string) => {
        // If not empty, then confirm remove
        if(subsite !== "") {
            if(!window.confirm(`Remove subsite: ${specialty} - ${subsite}?`))
                return
        }

        const newSpecialties = {...specialties}
        delete newSpecialties[specialty][subsite]
        setSpecialties(newSpecialties)
    }

    // Returns true or false if operation was valid
    const onChangeSpecialty = (oldSpecialty: string, newSpecialty: string): boolean => {
        if(specialties[newSpecialty] !== undefined)
            return false

        const newSpecialties = {...specialties}
        newSpecialties[newSpecialty] = newSpecialties[oldSpecialty]
        newSpecialties[oldSpecialty] = {}
        delete newSpecialties[oldSpecialty]
        setSpecialties(newSpecialties)
        return true
    }

    // Returns true or false if operation was valid
    const onChangeSubsite = (specialty: string, oldSubsite: string, newSubsite: string): boolean => {
        if(specialties[specialty][newSubsite] !== undefined)
            return false

        const newSpecialties = {...specialties}
        newSpecialties[specialty][newSubsite] = newSpecialties[specialty][oldSubsite]
        newSpecialties[specialty][oldSubsite] = { priority: 0 }
        delete newSpecialties[specialty][oldSubsite]
        setSpecialties(newSpecialties)
        return true
    }

    const onChangeSubsitePriority = (specialty: string, subsite: string, priority: string) => {
        let priorityNum = parseInt(priority)
        if(isNaN(priorityNum))
            priorityNum = 0
        const newSpecialties = {...specialties}
        newSpecialties[specialty][subsite] = { priority: priorityNum }
        setSpecialties(newSpecialties)
    }




    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <Divider/>
            </Grid> 
            {Object.keys(specialties).map((specialty, index) => 
                <React.Fragment key={`specialty_${index}`}>                   
                    <Grid item xs={12}>
                        <FilterInput
                            label="Specialty Name"
                            value={specialty}
                            onValueChange={(value) => onChangeSpecialty(specialty, value.trim())}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel>Subsite Name</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel>Subsite Priority (0 is lowest priority)</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                    {Object.keys(specialties[specialty]).map((subsite, index) => 
                        <React.Fragment key={`subsite_${index}`}>
                            <Grid item xs={4}>
                                <FilterInput
                                    value={subsite}
                                    onValueChange={(value) => onChangeSubsite(specialty, subsite, value.trim())}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    type="number"
                                    value={specialties[specialty][subsite].priority}
                                    onChange={(e) => onChangeSubsitePriority(specialty, subsite, e.target.value)}
                                    margin="dense"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Button disabled={isDisabled} onClick={() => {removeSubsite(specialty, subsite)}} variant="outlined" color="primary">
                                    Remove Subsite
                                </Button>
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid item>
                        <Button disabled={isDisabled} onClick={() => {addSubsite(specialty)}} variant="contained" color="primary">
                            Add Subsite
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button disabled={isDisabled} onClick={() => {removeSpecialty(specialty)}} variant="outlined" color="primary">
                            Remove Specialty
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid> 
                </React.Fragment>
            )}
            <Grid item xs={12}>
                <Button disabled={isDisabled} onClick={addSpecialty} variant="contained" color="primary">
                    Add Specialty
                </Button>
            </Grid>

        </Grid>  
    );
}

export default EditSpecialty;